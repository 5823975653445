import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { UserContext } from '../../context/user/context';
import { getWithExpiry } from '../../util/auth/drupal';
import { ToolTip } from '../global/ToolTip';
import useUpdateUserAttributes from '../../util/hooks/useUpdateUserAttributes';
import MailchimpSubscription from './MailchimpSubscription';
import { limiter } from '../../util/helper/limiter';

const FormUserInfo = () => {
  const [tooltip, setTooltip] = useState(false);
  const { state, userUpdateInfo } = useContext(UserContext);
  const { user } = state;

  // force refresh updates the
  // formik key to keep state consistent when
  // reloading the page
  const [ forceRefresh, setForceRefresh ] = useState(0);

  useEffect(() => {
    setForceRefresh(+forceRefresh+1);
  }, [user])

  const {
    attributeMessage,
    updatingAttributes,
    setUserAttributes,
  } = useUpdateUserAttributes(user.id);

  useEffect(() => {
    if (attributeMessage && updatingAttributes) {
      setTooltip(true);
      setTimeout(() => setTooltip(false), 3000);
    }
  }, [attributeMessage, updatingAttributes, setTooltip]);

  function validateEmail(value) {
    let error;
    if (!value) {
      error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (!value) {
      error = 'Please provide a username';
    }
    return error;
  }

  function validatePhone(value) {
    
    if (!value) return;

    let error;
    const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    if (!phoneRegExp.test(value)) {
      error = 'Please provide a valid phone number';
    }
    return error;
  }

  return (
    <Formik
      key={`user-attribute-form-${forceRefresh}`}
      initialValues={{
        field_firstname:
          user && user.field_firstname ? user.field_firstname : '',
        field_lastname: user && user.field_lastname ? user.field_lastname : '',
        field_email_address:
          user && user.field_email_address ? user.field_email_address : '',
        field_phone_other_1:
          user && user.field_phone_other_1 ? user.field_phone_other_1 : '',
        field_business_title:
          user && user.field_business_title ? user.field_business_title : '',
        field_salutation:
          user && user.field_salutation ? user.field_salutation : '',
        field_company: user && user.field_company ? user.field_company : '',
      }}
      onSubmit={async (values) => {
          setUserAttributes({
            attributes: values,
            token: getWithExpiry('csrf-token'),
          });
          userUpdateInfo(values);
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <div className="grid-2">
            <div className="input-item">
              <label className="t-small" htmlFor="full-name">
                First name
              </label>
              <Field
                name="field_firstname"
                id="field_firstname"
                type="text"
                placeholder="First name"
                validate={validateName}
              />
              {errors.field_firstname && touched.field_firstname && (
                <div>{errors.field_firstname}</div>
              )}
            </div>
            <div className="input-item">
              <label className="t-small" htmlFor="full-name">
                Last name
              </label>
              <Field
                name="field_lastname"
                id="field_lastname"
                type="text"
                placeholder="Last Name"
                validate={validateName}
              />
              {errors.field_lastname && touched.field_lastname && (
                <div>{errors.field_lastname}</div>
              )}
            </div>
          </div>
          <div className="input-item">
            <label className="t-small" htmlFor="user-email">
              Email
            </label>
            <Field
              name="field_email_address"
              id="field_email_address"
              type="email"
              placeholder="Email"
              validate={validateEmail}
            />
            {errors.validateEmail && touched.validateEmail && (
              <div>{errors.validateEmail}</div>
            )}
          </div>
          <div className="input-item">
              <MailchimpSubscription isSubmit={isSubmitting} />
          </div>
          <div className="input-item">
            <label className="t-small" htmlFor="mobile-number">
              Mobile number
            </label>
            <Field
              name="field_phone_other_1"
              id="field_phone_other_1"
              type="text"
              placeholder="Mobile number"
              validate={validatePhone}
            />
            {errors.field_phone_other_1 && touched.field_phone_other_1 && (
              <div>{errors.field_phone_other_1}</div>
            )}
          </div>
          <div className="input-item">
            <label className="t-small" htmlFor="mobile-number">
              Company
            </label>
            <Field
              name="field_company"
              id="field_company"
              type="text"
              placeholder="Company"
            />
          </div>
          <div className="grid-2">
            <div className="input-item">
              <label className="t-small" htmlFor="mobile-number">
                Salutation
              </label>
              <Field
                name="field_salutation"
                id="field_salutation"
                type="text"
                placeholder="Salutation"
              />
            </div>
            <div className="input-item">
              <label className="t-small" htmlFor="mobile-number">
                Title
              </label>
              <Field
                name="field_business_title"
                id="field_business_title"
                type="text"
                placeholder="Title"
              />
            </div>
          </div>
          <div className="spacer" style={{ position: 'relative' }}>
            <ToolTip isActive={tooltip}>{attributeMessage}</ToolTip>
          </div>
          <button
            type="submit"
            disabled={isSubmitting || updatingAttributes}
            className={`button btn-large btn-center btn-space-large`}
          >
            {isSubmitting || updatingAttributes
              ? `Submitting`
              : `Save Information`}
          </button>
        </Form>
      )}
    </Formik>
  );
};
export default FormUserInfo;
